import { Link } from "react-router-dom";

const Services = () => {
  return (
    <section id="services" className="services">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Services</h2>
          <p>Check our Services</p>
        </div>

        <div className="row">
          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bxl-dribbble"></i>
              </div>
              <h4>
                <Link to="/services/content-solutions">
                  Content Management Solutions
                </Link>
              </h4>
              <p>
                Our Content Management Solution empowers users to efficiently
                manage digital content, create engaging online experiences, and
                maintain dynamic websites without the need for extensive
                technical expertise.
              </p>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-file"></i>
              </div>
              <h4>
                <Link to="/services/website-app-development">
                  Web and Mobile Application Development
                </Link>
              </h4>
              <p>Get better results with data-driven UX</p>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-tachometer"></i>
              </div>
              <h4>
                <Link to="/services/data-analytics">Data Analytics</Link>
              </h4>
              <p>
                We design, develop, and deploy solutions across all stages of
                the data lifecycle.
              </p>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-world"></i>
              </div>
              <h4>
                <Link to="/services/ux-solutions">
                  Cloud and Infrastructure Services
                </Link>
              </h4>
              <p>We are experts of Cloud technologies in Azure, AWS and GCP.</p>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-slideshow"></i>
              </div>
              <h4>
                <Link to="/services/ux-solutions">UI/UX Development</Link>
              </h4>
              <p>
                By understanding users' goals, pain points, and behaviors, we
                can create solutions that meet their needs effectively.
              </p>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-arch"></i>
              </div>
              <h4>
                <a href="#">Managed Support Services</a>
              </h4>
              <p>
                Continuous monitoring of systems to detect and address issues
                promptly. Round-the-clock technical support to troubleshoot and
                resolve issues.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
