import { Link } from "react-router-dom";
import ImagesUtil from "./ImagesUtil";
import Placeholder from "../assets/img/640x360.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const PortfolioComponent = ({ portfolio }) => {
  let className = `col-lg-4 col-md-6 portfolio-item filter-${portfolio.type}`;

  return (
    <div className={className}>
      <div className="portfolio-wrap">
        <LazyLoadImage
          src={ImagesUtil.getImage(portfolio.image)}
          className="img-fluid"
          alt="/"
        />
        <div className="portfolio-info">
          <h4>{portfolio.name}</h4>
          <p>{portfolio.name}</p>
          <div className="portfolio-links">
            <a
              href={portfolio.image}
              data-gallery="portfolioGallery"
              className="portfolio-lightbox"
              title={portfolio.name}
            >
              <i className="bx bx-plus"></i>
            </a>
            <Link to="/portfolio-details" state={{ portfolio: portfolio }}>
              <i className="bx bx-link"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioComponent;
