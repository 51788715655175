import { Link } from "react-router-dom";

const ErpServices = () => {
  return (
    <section id="services" className="services">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Why Choose Us</h2>
          <p>Why Choose Us?</p>
        </div>

        <div className="row">
          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bxl-dribbble"></i>
              </div>
              <h4>
                <Link to="#">Expertise</Link>
              </h4>
              <p>
                Our team of certified Dynamics 365 consultants brings extensive
                experience and expertise in implementing and customizing
                Dynamics 365 for organizations across industries.
              </p>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-file"></i>
              </div>
              <h4>
                <Link to="#">Collaborative Approach</Link>
              </h4>
              <p>
                We believe in collaborating closely with our clients throughout
                the implementation process, ensuring that their unique needs and
                requirements are met every step of the way.
              </p>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-tachometer"></i>
              </div>
              <h4>
                <Link to="#">Customer-Centric</Link>
              </h4>
              <p>
                Our focus is always on delivering value to our clients and
                helping them achieve their business objectives through
                successful Dynamics 365 implementation.
              </p>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-world"></i>
              </div>
              <h4>
                <Link to="#">Fixed Cost Project</Link>
              </h4>
              <p>
                End to End Dynamics 365 services, from inception to
                delivery-handled by RoverTechSoft.
              </p>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-slideshow"></i>
              </div>
              <h4>
                <Link to="#">Managed Team</Link>
              </h4>
              <p>
                Get a cross functional team of Dynamics 365 Consultants, design,
                development and QA.
              </p>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-arch"></i>
              </div>
              <h4>
                <Link to="#">Staff Augmentation</Link>
              </h4>
              <p>
                Your access to experienced and certified Dynamics 365
                consultants to work on your new / existing projects.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ErpServices;
