import Isotope from "isotope-layout";
import { useEffect, useState } from "react";
import portfolios from "../data/portfolio.json";
import PortfolioComponent from "./PortfolioComponent";

export const Portfolio = () => {
  const [isotope, setIsotope] = useState(null);

  const [filterKey, setFilterKey] = useState("*");

  useEffect(() => {
    setIsotope(
      new Isotope(".portfolio-container", {
        itemSelector: ".portfolio-item",
        layoutMode: "fitRows",
      })
    );
  }, []);

  useEffect(() => {
    if (isotope) {
      filterKey === "*"
        ? isotope.arrange({ filter: `*` })
        : isotope.arrange({ filter: `.${filterKey}` });
    }
  }, [isotope, filterKey]);

  return (
    <section id="portfolio" className="portfolio">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Portfolio</h2>
          <p>Check our Portfolio</p>
        </div>

        <div className="row" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-12 d-flex justify-content-center">
            <ul id="portfolio-flters">
              <li
                className={filterKey === "*" ? "filter-active" : ""}
                onClick={() => setFilterKey("*")}
              >
                All
              </li>
              <li
                className={filterKey === "filter-app" ? "filter-active" : ""}
                onClick={() => setFilterKey("filter-app")}
              >
                App
              </li>
              <li
                className={filterKey === "filter-web" ? "filter-active" : ""}
                onClick={() => setFilterKey("filter-web")}
              >
                Web
              </li>
              <li
                className={filterKey === "filter-erp" ? "filter-active" : ""}
                onClick={() => setFilterKey("filter-erp")}
              >
                ERP
              </li>
            </ul>
          </div>
        </div>

        <div
          className="row portfolio-container"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          {portfolios.map((portfolio) => {
            return (
              <PortfolioComponent key={portfolio.id} portfolio={portfolio} />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
