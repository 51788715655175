import ImagesUtil from "./ImagesUtil";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Hero = () => {
  return (
    <section
      id="hero"
      className="d-flex align-items-center justify-content-center"
    >
      <div className="container" data-aos="fade-up">
        <div
          className="row justify-content-center"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <div className="col-xl-6 col-lg-8">
            <a href="index.html" className="logo me-auto me-lg-0">
              <LazyLoadImage
                src={ImagesUtil.getImage("Logo")}
                style={{ width: "200px" }}
                className="img-fluid"
              />
            </a>
            <h1>Digital Transformation</h1>
            <h2>Accelerating Your Journey to Digital Excellence</h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
