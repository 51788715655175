import Header from "../components/Header";
import { useEffect } from "react";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import content from "../data/data-analytics.json";
import ImagesUtil from "../components/ImagesUtil";
import Footer from "../components/Footer";
import BackToTop from "../components/BackToTop";
import { LazyLoadImage } from "react-lazy-load-image-component";

const DataAnalytics = () => {
  const classNames = "header-inner-pages fixed-top";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navPaths = {
    home: "/#hero",
    about: "/#about",
    portfolio: "/#portfolio",
    contact: "/#contact",
  };

  return (
    <ParallaxProvider>
      <Header classNames={classNames} navPaths={navPaths} />
      <header
        id="services"
        className="bg-dark py-5"
        style={{
          backgroundImage: `url(${ImagesUtil.getImage(content.hero.image)})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container px-5">
          <div className="row gx-5 align-items-center justify-content-center">
            <div className="col-lg-8 col-xl-7 col-xxl-6">
              <div className="my-5 text-center text-xl-start">
                <h1 className="display-5 fw-bolder text-white mb-2">
                  {content.hero.title}
                </h1>
                <h4>{content.hero.description}</h4>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Parallax y={[-0, 0]}>
        <section className="py-5 bg-light" id="scroll-target">
          <div className="container-fluid my-5">
            <div className="row gx-5 align-items-top">
              <div className="col-lg-6">
                <LazyLoadImage
                  className="img-fluid rounded mb-5 mb-lg-0"
                  src={ImagesUtil.getImage(content.services[0].image)}
                  alt="..."
                />
              </div>
              <div className="col-lg-6">
                <h2 className="fw-bolder">{content.services[0].title}</h2>
                <p className="lead fw-normal text-muted mb-0">
                  {content.services[0].description}
                </p>
              </div>
            </div>
          </div>
        </section>
      </Parallax>
      <section className="bg-light" id="scroll-target">
        <div className="container-fluid my-5">
          <div className="row gx-5 align-items-top">
            <div className="col-lg-6">
              <h2 className="fw-bolder">{content.services[1].title}</h2>
              <p className="lead fw-normal text-muted mb-0">
                {content.services[1].description}
              </p>
            </div>
            <div className="col-lg-6">
              <LazyLoadImage
                className="img-fluid rounded mb-5 mb-lg-0"
                src={ImagesUtil.getImage(content.services[1].image)}
                alt="..."
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light" id="scroll-target">
        <div className="container-fluid my-5">
          <div className="row gx-5 align-items-top">
            <div className="col-lg-6">
              <LazyLoadImage
                className="img-fluid rounded mb-5 mb-lg-0"
                src={ImagesUtil.getImage(content.services[2].image)}
                alt="..."
              />
            </div>

            <div className="col-lg-6">
              <h2 className="fw-bolder">{content.services[2].title}</h2>
              <p className="lead fw-normal text-muted mb-0">
                {content.services[2].description}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light" id="scroll-target">
        <div className="container-fluid my-5">
          <div className="row gx-5 align-items-top">
            <div className="col-lg-6">
              <h2 className="fw-bolder">{content.services[3].title}</h2>
              <p className="lead fw-normal text-muted mb-0">
                {content.services[3].description}
              </p>
            </div>
            <div className="col-lg-6">
              <LazyLoadImage
                className="img-fluid rounded mb-5 mb-lg-0"
                src={ImagesUtil.getImage(content.services[3].image)}
                alt="..."
              />
            </div>
          </div>
        </div>
      </section>

      <Footer navPaths={navPaths} />
      <BackToTop />
    </ParallaxProvider>
  );
};

export default DataAnalytics;
