import React from "react";
import PhoneBook from "../assets/img/portfolio/my-phone-book.png";
import Elearn from "../assets/img/portfolio/e-learn.png";
import EcoCrew from "../assets/img/portfolio/ecocrew.png";
import Kelasa from "../assets/img/portfolio/kelasa.png";
import GrayBox from "../assets/img/portfolio/gray-box.png";
import Mendit from "../assets/img/portfolio/mendit.png";
import IIPC from "../assets/img/portfolio/iipc.png";
import PartTimer from "../assets/img/portfolio/parttimer.png";
import QPlease from "../assets/img/portfolio/qplease.png";
import ADMS from "../assets/img/portfolio/adms.png";
import DigitalTech from "../assets/img/digital-tech.jpg";
import Website from "../assets/img/website.jpg";
import Personalize from "../assets/img/person.jpg";
import WebsiteDevelopment from "../assets/img/website-development.jpg";
import WebsiteStructure from "../assets/img/website-structure.jpg";
import MobileApp from "../assets/img/mobile-app.jpg";
import WebAPI from "../assets/img/WebAPI.jpg";
import WebQA from "../assets/img/WebQA.jpg";
import DataAnalytics from "../assets/img/data-analytics.jpg";
import DataStrategy from "../assets/img/data-strategy.jpg";
import Reports from "../assets/img/reports.jpg";
import UXHero from "../assets/img/UXHero.jpg";
import UXDesign from "../assets/img/ux-design.jpg";
import UXDesign2 from "../assets/img/UXDesign2.jpg";
import UXDesign3 from "../assets/img/UXDesign3.jpg";
import UXDesign4 from "../assets/img/UXDesign4.jpg";
import UXDesign5 from "../assets/img/UXDesign5.jpg";
import DataMgmt from "../assets/img/data-mgmt.jpg";
import DataSecu from "../assets/img/data-security.jpg";
import Logo from "../assets/img/logo.jpeg";
import ERPMastHead from "../assets/img/erp-masthead.jpg";
import ERPModules from "../assets/img/erp.jpg";
import Industry from "../assets/img/industry.jpg";
import Vision from "../assets/img/vision.jpg";
import Electronics from "../assets/img/portfolio/electronics.jpg";
import Components from "../assets/img/portfolio/components.jpg";

class ImagesUtil extends React.Component {
  static getImage(imageName) {
    let imagePath;
    switch (imageName) {
      case "PhoneBook":
        imagePath = PhoneBook;
        break;
      case "EcoCrew":
        imagePath = EcoCrew;
        break;
      case "Kelasa":
        imagePath = Kelasa;
        break;
      case "Mendit":
        imagePath = Mendit;
        break;
      case "IIPC":
        imagePath = IIPC;
        break;
      case "Elearn":
        imagePath = Elearn;
        break;
      case "GrayBox":
        imagePath = GrayBox;
        break;
      case "ParTimer":
        imagePath = PartTimer;
        break;
      case "QPlease":
        imagePath = QPlease;
        break;
      case "ADMS":
        imagePath = ADMS;
        break;
      case "DigitalTech":
        imagePath = DigitalTech;
        break;
      case "Website":
        imagePath = Website;
        break;
      case "Personalize":
        imagePath = Personalize;
        break;
      case "WebsiteDevelopment":
        imagePath = WebsiteDevelopment;
        break;
      case "WebsiteStructure":
        imagePath = WebsiteStructure;
        break;
      case "MobileApp":
        imagePath = MobileApp;
        break;
      case "WebAPI":
        imagePath = WebAPI;
        break;
      case "WebQA":
        imagePath = WebQA;
        break;
      case "DataAnalytics":
        imagePath = DataAnalytics;
        break;
      case "DataStrategy":
        imagePath = DataStrategy;
        break;
      case "DataMgmt":
        imagePath = DataMgmt;
        break;
      case "DataSecu":
        imagePath = DataSecu;
        break;
      case "Reports":
        imagePath = Reports;
        break;
      case "UXHero":
        imagePath = UXHero;
        break;
      case "UXDesign":
        imagePath = UXDesign;
        break;
      case "UXDesign2":
        imagePath = UXDesign2;
        break;
      case "UXDesign3":
        imagePath = UXDesign3;
        break;
      case "UXDesign4":
        imagePath = UXDesign4;
        break;
      case "UXDesign5":
        imagePath = UXDesign5;
        break;
      case "Logo":
        imagePath = Logo;
        break;
      case "ERPMastHead":
        imagePath = ERPMastHead;
        break;
      case "ERPCycle":
        imagePath = ERPMastHead;
        break;
      case "Industry":
        imagePath = Industry;
        break;
      case "Vision":
        imagePath = Vision;
        break;
      case "ERPModules":
        imagePath = ERPModules;
        break;
      case "Electronics":
        imagePath = Electronics;
        break;
      case "Components":
        imagePath = Components;
        break;
      default:
        imagePath = "Default option";
    }
    return imagePath;
  }
}

export default ImagesUtil;
