import { Nav, NavDropdown } from "react-bootstrap";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";

const Header = ({ classNames, navPaths }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [isErp, setIsErp] = useState(false);

  const { home, about, portfolio, contact } = navPaths;

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const handleErpEnter = () => {
    setIsOpen(true);
  };

  const handleErpLeave = () => {
    setIsOpen(false);
  };

  return (
    <header id="header" className={classNames}>
      <div className="container d-flex align-items-center justify-content-lg-between">
        <Nav id="navbar" className="navbar order-last order-lg-0">
          <Nav.Item>
            <Link to={home} className="nav-link scrollto">
              Home
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to={about} className="nav-link scrollto">
              About
            </Link>
          </Nav.Item>
          <Nav.Item></Nav.Item>
          <Nav.Item>
            <Link to={portfolio} className="nav-link scrollto">
              Portfolio
            </Link>
          </Nav.Item>

          <NavDropdown
            title="Services"
            id="basic-nav-dropdown"
            onMouseEnter={handleErpEnter}
            onMouseLeave={handleErpLeave}
            show={isOpen}
          >
            <Link to="/services/content-solutions" className="dropdown-item">
              Content Management
            </Link>
            <DropdownSubmenu
              href="#action/3.7"
              title="ERP"
              onMouseEnter={handleErpEnter}
              onMouseLeave={handleErpLeave}
            >
              <Link
                to="/services/microsoft-dynamics-solutions"
                className="dropdown-item"
              >
                Microsoft Dynamics Implementation
              </Link>
            </DropdownSubmenu>
            <Link
              to="/services/website-app-development"
              className="dropdown-item"
            >
              Web and Mobile Development
            </Link>
            <Link to="/services/data-analytics" className="dropdown-item">
              Data Analytics
            </Link>
            <Link to="/services/ux-solutions" className="dropdown-item">
              UI/UX Solutions
            </Link>
          </NavDropdown>
        </Nav>
        <i className="bi bi-list mobile-nav-toggle"></i>

        <Nav id="navbar">
          <Nav.Item>
            <Link to={contact} className="get-started-btn scrollto">
              Contact Us
            </Link>
          </Nav.Item>
        </Nav>
      </div>
    </header>
  );
};

export default Header;
