import "./App.css";
import "./assets/css/vendor/aos/aos.css";
import "./assets/css/vendor/bootstrap/css/bootstrap.min.css";
import "react-bootstrap-submenu/dist/index.css";
import "./assets/css/vendor/bootstrap-icons/bootstrap-icons.css";
import "./assets/css/vendor/boxicons/css/boxicons.min.css";
import "./assets/css/vendor/glightbox/css/glightbox.min.css";
import "./assets/css/vendor/remixicon/remixicon.css";
import "./assets/css/vendor/swiper/swiper-bundle.min.css";
import "./assets/css/style.css";

import Home from "./screens/Home";

import { Routes, Route, HashRouter } from "react-router-dom";
import { PortfolioDetails } from "./screens/PortfolioDetails";
import ContentSolutions from "./screens/ContentSolutions";
import MicrosoftDynamicsSolutions from "./screens/MicrosoftDynamicsSolutions";
import WebsiteDevelopment from "./screens/WebsiteAppDevelopment";
import DataAnalytics from "./screens/DataAnalytics";
import UXDevelopment from "./screens/UXDevelopment";

function App() {
  const baseUrl = process.env.PUBLIC_URL;
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/services/content-solutions"
          element={<ContentSolutions />}
        />
        <Route
          path="/services/microsoft-dynamics-solutions"
          element={<MicrosoftDynamicsSolutions />}
        />
        <Route
          path="/services/website-app-development"
          element={<WebsiteDevelopment />}
        />
        <Route path="/services/data-analytics" element={<DataAnalytics />} />
        <Route path="/services/ux-solutions" element={<UXDevelopment />} />
        <Route path="/portfolio-details" element={<PortfolioDetails />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
