import Client1 from "../assets/img/clients/client-1.jpg";
import ParTimer from "../assets/img/clients/partimer.jpg";
import EcoLogo from "../assets/img/clients/eco-logo.jpg";
import Client4 from "../assets/img/clients/client-4.png";
import Client5 from "../assets/img/clients/client-5.png";
import Client6 from "../assets/img/clients/client-6.png";
import Client7 from "../assets/img/clients/client-7.png";
import Client8 from "../assets/img/clients/client-8.png";
import Placeholder from "../assets/img/640x360.png";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay, Navigation } from "swiper";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Clients = () => {
  SwiperCore.use([Pagination, Autoplay, Navigation]);

  const breakpoints = {
    320: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    480: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
    1024: {
      slidesPerView: 6,
      spaceBetween: 80,
    },
  };

  return (
    <section id="clients" className="clients">
      <div className="container" data-aos="zoom-in">
        <Swiper
          className="clients-slider"
          slidesPerView="auto"
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          speed={400}
          breakpoints={breakpoints}
          pagination={{
            clickable: true,
            type: "bullets",
          }}
        >
          <div className="swiper-wrapper align-items-center">
            <SwiperSlide>
              <LazyLoadImage src={Client1} className="img-fluid" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <LazyLoadImage src={ParTimer} className="img-fluid" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <LazyLoadImage src={EcoLogo} className="img-fluid" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <LazyLoadImage src={Client4} className="img-fluid" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <LazyLoadImage src={Client5} className="img-fluid" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <LazyLoadImage src={Client6} className="img-fluid" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <LazyLoadImage src={Client7} className="img-fluid" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <LazyLoadImage src={Client8} className="img-fluid" alt="" />
            </SwiperSlide>
          </div>
          <div className="swiper-pagination swiper-pagination-timeline-page" />
        </Swiper>
      </div>
    </section>
  );
};

export default Clients;
