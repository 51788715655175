import { Link, useLocation } from "react-router-dom";
import ImagesUtil from "../components/ImagesUtil";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Footer from "../components/Footer";

export const PortfolioDetails = () => {
  const navPaths = {
    home: "#hero",
    about: "#about",
    portfolio: "#portfolio",
    contact: "/#contact",
  };

  const location = useLocation();

  const { portfolio } = location.state;

  return (
    <main id="main">
      <section id="breadcrumbs" className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Portfolio Details</h2>
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>{portfolio.name}</li>
            </ol>
          </div>
        </div>
      </section>

      <section id="portfolio-details" className="portfolio-details">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-8">
              <div className="portfolio-details-slider swiper">
                <div className="swiper-wrapper align-items-center">
                  <div className="swiper-slide">
                    <LazyLoadImage
                      src={ImagesUtil.getImage(portfolio.image)}
                      alt=""
                    />
                  </div>
                </div>
                <div className="swiper-pagination"></div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="portfolio-info">
                <h3>Project information</h3>
                <ul>
                  <li>
                    <strong>Category</strong>: {portfolio.category}
                  </li>
                  <li>
                    <strong>Client</strong>: {portfolio.name}
                  </li>
                </ul>
              </div>
              <div className="portfolio-description">
                <h2>{portfolio.name}</h2>
                <p>{portfolio.description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer navPaths={navPaths} />
    </main>
  );
};
