import Header from "../components/Header";
import { useEffect } from "react";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import erp from "../data/erp.json";
import ImagesUtil from "../components/ImagesUtil";
import Footer from "../components/Footer";
import BackToTop from "../components/BackToTop";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Services from "../components/Services";
import ErpServices from "../components/ErpServices";

const MicrosoftDynamicsSolutions = () => {
  const classNames = "header-inner-pages fixed-ptop";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navPaths = {
    home: "/#hero",
    about: "/#about",
    portfolio: "/#portfolio",
    contact: "/#contact",
  };

  return (
    <ParallaxProvider>
      <Header classNames={classNames} navPaths={navPaths} />
      <header
        className="headerImage"
        style={{
          backgroundImage: `url(${ImagesUtil.getImage(erp.hero.image)})`,
        }}
      ></header>

      <Parallax y={[-0, 0]}>
        <section className="py-5 bg-light" id="scroll-target">
          <div className="container my-5">
            <div className="row gx-5 align-items-top">
              <div className="col-lg-6">
                <LazyLoadImage
                  className="img-fluid rounded mb-5 mb-lg-0"
                  src={ImagesUtil.getImage(erp.services[0].image)}
                  alt="..."
                />
              </div>
              <div className="col-lg-6">
                <h2 className="fw-bolder">{erp.services[0].title}</h2>
                <p className="lead fw-normal text-muted mb-0">
                  {erp.services[0].description}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="features" class="features">
          <div class="container aos-init aos-animate" data-aos="fade-up">
            <div class="row">
              <div
                class="image aos-init aos-animate"
                style={{
                  backgroundImage: `url(${ImagesUtil.getImage("ERPModules")})`,
                }}
                data-aos="fade-right"
              ></div>
            </div>
            <div className="row mt-5">
              <div
                class="col-lg-12 aos-init aos-animate"
                data-aos="fade-left"
                data-aos-delay="100"
              >
                <div className="row">
                  <div className="col-lg-6">
                    <div
                      className="icon-box mt-5 mt-lg-0 aos-init aos-animate mt-5"
                      data-aos="zoom-in"
                      data-aos-delay="150"
                    >
                      <i class="bx bx-receipt"></i>
                      <h4>Discovery and Assessment</h4>
                      <p>
                        We'll conduct a comprehensive assessment of your
                        business processes, systems, and technology
                        infrastructure to identify opportunities for improvement
                        and determine the best approach for your Dynamics 365
                        implementation.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="icon-box aos-init aos-animate"
                      data-aos="zoom-in"
                      data-aos-delay="150"
                    >
                      <i class="bx bx-cube-alt"></i>
                      <h4>Implementation and Configuration</h4>
                      <p>
                        Our team will work diligently to configure and customize
                        Dynamics 365 to meet your specific business
                        requirements, ensuring that the solution aligns
                        seamlessly with your existing processes and workflows.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div
                class="col-lg-12 aos-init aos-animate"
                data-aos="fade-left"
                data-aos-delay="100"
              >
                <div className="row">
                  <div className="col-lg-6">
                    <div
                      className="icon-box mt-5 mt-lg-0 aos-init aos-animate mt-5"
                      data-aos="zoom-in"
                      data-aos-delay="150"
                    >
                      <i class="bx bx-receipt"></i>
                      <h4>Data Migration and Integration</h4>
                      <p>
                        We'll help you migrate your data from legacy systems to
                        Dynamics 365 and ensure seamless integration with other
                        applications and services within your ecosystem.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="icon-box aos-init aos-animate"
                      data-aos="zoom-in"
                      data-aos-delay="150"
                    >
                      <i class="bx bx-cube-alt"></i>
                      <h4>Training and Support</h4>
                      <p>
                        We provide comprehensive training and support to ensure
                        a smooth transition to Dynamics 365 and empower your
                        team to leverage the full capabilities of the platform.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Parallax>
      <ErpServices />
      <Footer navPaths={navPaths} />
      <BackToTop />
    </ParallaxProvider>
  );
};

export default MicrosoftDynamicsSolutions;
