import { useEffect } from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import AboutUs from "../components/AboutUs";
import AOS from "aos";
import Clients from "../components/Clients";
import Services from "../components/Services";
import Portfolio from "../components/Portfolio";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import BackToTop from "../components/BackToTop";

const Home = () => {
  const classNames = "fixed-top header-inner-pages";

  const navPaths = {
    home: "#hero",
    about: "#about",
    portfolio: "#portfolio",
    contact: "/#contact",
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  });

  return (
    <>
      <Header classNames={classNames} navPaths={navPaths}/>
      <Hero />
      <AboutUs/>
      {/* <Clients/> */}
      <Services/>
      <Portfolio/>
      <ContactUs/>
      <Footer navPaths={navPaths}/>
      <BackToTop/>
    </>
  );
};

export default Home;
