import { LazyLoadImage } from "react-lazy-load-image-component";
import About from "../assets/img/about.jpg";

const AboutUs = () => {
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div
            className="col-lg-6 order-1 order-lg-2"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <LazyLoadImage src={About} className="img-fluid" alt="" />
          </div>
          <div
            className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <h1>Who We Are</h1>
            <p className="fst-italic">
              We are a leading digital consulting company specializing in
              helping businesses navigate the rapidly evolving digital
              landscape. With a deep understanding of technology trends and
              consumer behavior, we empower our clients to achieve their digital
              transformation goals and stay ahead of the competition.
            </p>
            <ul>
              <li>
                <i className="ri-check-double-line"></i>{" "}
                <b>Client-Centric Approach:</b> We believe in building strong
                partnerships with our clients. By truly understanding their
                businesses, we develop customized strategies that deliver
                tangible results and create long-term value.
              </li>
              <li>
                <i className="ri-check-double-line"></i>{" "}
                <b>Expertise and Excellence:</b> Our team consists of highly
                skilled professionals with extensive experience in digital
                strategy, technology implementation, and process optimization.
                We stay at the forefront of industry trends to provide
                forward-thinking solutions.
              </li>
              <li>
                <i className="ri-check-double-line"></i>{" "}
                <b>Integrity and Ethics:</b> We conduct business with the utmost
                integrity, honesty, and ethical behavior ensuring
                confidentiality, data privacy, and compliance with legal and
                regulatory requirements.
              </li>
              <li>
                <i className="ri-check-double-line"></i>{" "}
                <b>Holistic Solutions:</b> We offer end-to-end digital
                consulting services, covering everything from strategic planning
                and digital marketing to software development and data
                analytics. Our comprehensive approach ensures a seamless digital
                transformation journey.
              </li>
              <li>
                <i className="ri-check-double-line"></i> <b>Innovation:</b>{" "}
                Innovation is at the core of what we do. We continuously explore
                emerging technologies and industry best practices to identify
                new opportunities for our clients.
              </li>
            </ul>
            <p>
              With a proven track record of success and a team of highly skilled
              professionals, we are committed to helping our clients navigate
              the ever-evolving landscape of technology and achieve their
              strategic objectives.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
